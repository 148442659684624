import Vue from "vue";
import vSelect from "vue-select";
import {loadMap, getMapKey} from "./maptiler";
import fachowcy from "./fachowcy";
import IT_CATEGORIES from "./it_categories";
import itFeaturedoffereCarousel from "./it_featuredoffers_carousel";

Vue.component("v-select", vSelect);

window.offerSearchComponent = null;
window.setVue = function (vuecomponent) {
    window.offerSearchComponent = vuecomponent;
}

if (document.getElementById('app-erecruter')) {

    window.onlyForm = document.getElementById('app-erecruter').dataset.onlyForm === 'true';
    window.dontLoadList = document.getElementById('app-erecruter').dataset.loadList === 'false';
    window.liksPage = document.getElementById('app-erecruter').dataset.liksPage === 'true';
    window.withoutCv = document.getElementById('app-erecruter').dataset.withoutCv === 'true';
    window.area = document.getElementById('app-erecruter').dataset.area;
    const ids = [];
    if(document.getElementById('app-erecruter').dataset.ids){
        document.getElementById('app-erecruter').dataset.ids.split(',').forEach((id) => {
            ids.push(id);
        });
    }


    //get variable form url ?targets
    let url = new URL(window.location.href);
    let targets = url.searchParams.get('targets') ? url.searchParams.get('targets').split('|') : null;
    console.log('targets', targets);

    /**
     * data-filter powinien zawierać json z polami zwracanymi przez api/list/. Przykład:
     * {
     *     "city": "Warszawa",
     * }
     *
     * lub
     * {
     *     "city": ["Warszawa", "Kraków"],
     * }
     * @type {null}
     */
    let offerFilter = null;
    if (document.getElementById('app-erecruter').dataset.filter) {
        offerFilter = JSON.parse(document.getElementById('app-erecruter').dataset.filter);
    }

    window.erecruterApp = new Vue({
        el: '#app-erecruter',
        data: {
            settings: {
                url: process.env.API_URL,
                // url: 'https://api.local',
                key: process.env.API_TOKEN,
                citiesEnabled: true,
                departmentsEnabled: true,
                areasEnabled: true,
                loadListAfterStart: !window.dontLoadList,
                loaderClassName: 'lds-ripple',
                loaderVisibleClassName: 'is-visible',
                contentClassName: 'js-results',
            },

            resultsPage: process.env.SEARCH_PAGE_FILTER,
            resultsItPage: process.env.SEARCH_IT_PAGE_FILTER || (window.location.href.includes("leroy-merlin-it/") ? 'leroy-merlin-it/it-wyszukiwarka.html' : 'it-wyszukiwarka.html'),
            cities: [],
            departments: [],
            areas: [],
            distances: [],
            list: [],
            listBackup: [],
            liked: [],
            city: null,
            department: null,
            area: null,
            remote: false,
            title: null,
            search: '',
            search2: '',
            distance: {label: '+ 15 km', code: 15},
            isOpen: false,
            timeout: null,
            showContentSearch: false,
            showPagination: false,
            showListWithResults: false,
            showListWithoutResults: false,
            showJsCloseResults: false,
            onlyForm: false,
            doneRequests: 0,
            enabledWatch: false,
            type: null,

            it_raw_list: [], //cała lista ofert it bez filtrowania

            it_raw_positions: [], //baza pozycji it
            it_positions: [],
            it_position: null,

            it_departments: [],
            it_department: null,

            it_department_page: document.getElementById('app-erecruter').dataset.defaultItCategory || null, //przechowuje wybrany działa na stronie it-dzialy.html

            it_work_types: ['zdalnie', 'stacjonarnie', 'hybrydowo'],
            it_work_type: null,

            promotedOffers: [],

            // pagination vars
            currentPage: 1,
            totalPages: 1,
            perPage: window.innerWidth > 768 ? 6 : 4,
            indexOfLastOffer: 1,
            indexOfFirstOffer: 1,
            showPages: [],
            currentOffers: [],
        },
        created: function () {
            setVue(this);
        },
        mounted: function () {

            if (this.$el.dataset.type)
                this.type = this.$el.dataset.type;
            else {
                const url = new URL(window.location.href);
                this.type = url.searchParams.get('type');
            }

            if (this.type == 'it') {
                this.liked = localStorage.getItem('likedit') ? JSON.parse(localStorage.getItem('likedit')) : [];
            } else {
                this.liked = localStorage.getItem('liked') ? JSON.parse(localStorage.getItem('liked')) : [];
            }

            if (this.type != 'fachowcy') {
                if (document.getElementById('topbar-fachowcy'))
                    document.getElementById('topbar-fachowcy').remove();
            } else if (document.getElementById('topbar')) {
                document.getElementById('topbar').remove();
            }

            // let that = this;
            this.distances = this.distanceGenerator();
            this.areas = this.areaGenerator();
            this.departments = this.departmentsGenerator();
            // if (this.settings.citiesEnabled) {
            //   this.request("/api/locations/" + this.settings.key, function (data) {
            //     that.cities = data;
            //     ++that.doneRequests;
            //     that.openListAfterLoadAllData();
            //   });
            // }
            // if (this.settings.categoriesEnabled) {
            //   this.request("/api/categories/" + this.settings.key, function (data) {
            //     that.categories = data;
            //     ++that.doneRequests;
            //     that.openListAfterLoadAllData();
            //   });
            // }
            // if (this.settings.departmentsEnabled) {
            //   this.request("/api/departments/" + this.settings.key, function (data) {
            //     that.departments = data;
            //     ++that.doneRequests;
            //     that.openListAfterLoadAllData();
            //   });
            // }



            // if exists $_GET['targets'] then set custom targets
            if (targets) {
              this.openCustomTargets(targets);
              return;
            }

            this.openListAfterLoadAllData();
            let that = this;

            this.loadPromotedOffers();

            setTimeout(() => that.enabledWatch = true);
        },
        computed: {
            urlOffer: function () {
                let url = this.type == 'fachowcy' ? document.getElementById('app-erecruter').getAttribute('data-offer-url').replace('.html', '-fachowcy.html') : document.getElementById('app-erecruter').getAttribute('data-offer-url');
                if(window.location.href.includes("leroy-merlin-video/")){
                    url = '/leroy-merlin-video/' + url;
                } else if(window.location.href.includes("leroy-merlin-przedstawiciel-handlowy/")){
                    url = '/leroy-merlin-przedstawiciel-handlowy/' + url;
                }

                return url;
            },
            foundOffers: function () {
                if (this.list.length < 1)
                    return 'ofert';
                else if (this.list.length === 1)
                    return 'ofertę';
                else if (this.list.length > 1 && this.list.length < 5)
                    return 'oferty';
                else
                    return 'ofert';
            }
        },
        watch: {
            city: function (newVal, oldVal) {
                if (!window.onlyForm && !window.liksPage && !window.withoutCv) {
                    this.filterByGeolocation(true);
                }
            },
            distance: function (newVal, oldVal) {
                if (!window.onlyForm && !window.liksPage && !window.withoutCv && this.city) {
                    this.filterByGeolocation(true);
                }
            },
            area: function (newVal, oldVal) {
                if (this.enabledWatch) {
                    this.departments = this.departmentsGenerator();
                    this.department = null;

                    if (newVal !== 'Centrala') {
                        this.remote = false;
                    }
                    this.selected();
                }
            },
            department: function (newVal, oldVal) {
                if (newVal.includes('IT')) {
                    this.remote = false;
                    this.city = null;
                    this.distance = {label: '+ 15 km', code: 15};
                    if (newVal.length > 1 && newVal[newVal.length - 1] == 'IT') {
                        this.department = ['IT'];
                    } else if (newVal.length > 1 && newVal[0] == 'IT') {
                        this.department = newVal.filter((item) => item !== 'IT');
                    }
                }
                if (this.enabledWatch) {
                    this.selected();
                }
            },
            remote: function (newVal, oldVal) {
                if (this.enabledWatch) {
                    if (newVal) {
                        this.city = null;
                    }
                    this.selected();
                }
            },
            it_work_type: function (newVal, oldVal) {
                this.remote = false;
                if (newVal && newVal.length) {
                    newVal.forEach((type) => {
                        if (type === 'zdalnie') {
                            this.city = null;
                            this.remote = true;
                        }
                    });
                }

            },
            currentOffers: function (newVal, oldVal) {
                if (newVal.length > 0 && this.type == 'it') {
                    document.body.classList.remove('no-offers');
                } else if (this.type == 'it') {
                    document.body.classList.add('no-offers');
                }
            },
        },
        methods: {
            openListAfterLoadAllData: function () {
                // if (this.doneRequests === 2) {
                if (!window.dontLoadList && this.settings.contentClassName && document.getElementsByClassName(this.settings.contentClassName)[0]) {
                    document.getElementsByClassName(this.settings.contentClassName)[0].style.display = 'block';
                }

                setTimeout(() => {
                    // Detect url params
                    const queryString = window.location;

                    let city = null;
                    let distance = null;
                    let department = null;
                    let area = null;
                    let title = null;
                    let remote = false;

                    if (queryString) {
                        let url = new URL(queryString),
                            params = new URLSearchParams(url.search);
                        city = params.get('city');
                        distance = params.get('distance');
                        department = params.get('departments_arr');
                        area = params.get('area');
                        title = params.get('titles_arr');
                        remote = params.get('remote');
                        // search = params.get('s');

                        if (this.type == 'it') {


                            if (params.get('department')) {
                                this.it_department = params.get('department').split('||');
                            }

                            if (params.get('work_type')) {
                                this.it_work_type = params.get('work_type').split('||');
                            }

                            if (params.get('position')) {
                                this.it_position = params.get('position').split('||').map(item => {
                                    return {label: item}
                                });
                            }

                            if (params.get('city')) {
                                this.city = params.get('city').split('||').map(item => {
                                    return {city: item}
                                });
                            }

                            this.getList(true);
                            return;
                        }
                    }

                    if (window.area !== undefined) {
                        area = window.area;
                    }

                    if (city) {
                        this.city = JSON.parse(city);
                    }
                    if (distance) {
                        this.distance = {label: '+ ' + distance + ' km', code: distance};
                    }
                    if (area) {
                        this.area = area;
                        this.departments = this.departmentsGenerator();
                    }

                    if (department) {
                        this.department = department.split('||');
                    }
                    if (title) {
                        this.title = title;
                    }

                    this.remote = !!remote;


                    if (this.settings.loadListAfterStart) {
                        this.getList(true);
                    }
                });
                // }
            },
            searchLocations: function (search, loading) {
                clearTimeout(this.timeout);

                if (!search.length) {
                    loading(false);
                    return;
                }

                loading(true);

                let that = this;
                this.timeout = setTimeout(() => {
                    that.request("/api/locations/search?search=" + search, function (data) {
                        that.cities = data;
                        loading(false);
                    }, false, true);
                }, 300);

            },
            searchItPosition: function (search, loading) {
                clearTimeout(this.timeout);

                if (!search.length) {
                    loading(false);
                    return;
                }

                loading(true);

                //search in it_positions in label and technologies
                this.timeout = setTimeout(() => {
                    this.it_positions = this.it_raw_positions.filter((item) => {
                        return item.label.toLowerCase().includes(search.toLowerCase());
                    });
                    loading(false);
                }, 300);
            },
            distanceGenerator: function () {
                return [
                    {label: '+ 15 km', code: 15},
                    {label: '+ 30 km', code: 30},
                    {label: '+ 45 km', code: 45},
                    {label: '+ 60 km', code: 60},
                ];
            },
            categoriesGenerator: function () {
                if (this.type == 'fachowcy')
                    return fachowcy();

                return [
                    {
                        name: 'Sklep',
                        list: [
                            'Kasy',
                            'Serwis i usługi',
                            'Przedstawiciel handlowy',
                            'Handel',
                            'Logistyka/Magazyn',
                            'Projektowanie wnętrz',
                            'Biuro',
                        ]
                    },
                    {
                        name: 'Centrala',
                        list: [
                            'Zasoby ludzkie',
                            'Zakupy',
                            'IT',
                            'Przedstawiciel handlowy',
                            // 'Zarządzanie projektami',
                            'Finanse',
                            'Marketing',
                            'Łańcuch dostaw',
                            'Rozwój organizacji',
                        ]
                    },
                    {
                        name: 'Staże/Praktyki',
                        list: [
                            'Staże/Praktyki'
                        ]
                    },
                ];
            },
            areaGenerator: function () {
                let list = [];
                this.categoriesGenerator().forEach((item) => list.push(item.name));
                return list;
            },
            departmentsGenerator: function () {
                let list = [];
                this.categoriesGenerator().forEach((item) => {
                    if (!this.area || this.area.indexOf(item.name) > -1) {
                        item.list.forEach((d) => list.push(d))
                    }
                });
                return list;
            },
            runGeolocation: function () {
                if (this.remote) {
                    return;
                }
                try {
                    navigator.geolocation.getCurrentPosition((position) => {
                        let that = this;
                        this.request(
                            "https://api.maptiler.com/geocoding/" + position.coords.longitude + "," + position.coords.latitude + ".json?language=pl&key=" + getMapKey(),
                            function (data) {
                                let place = data.features.find((item) => item.place_type[0] === "place");
                                let municipality = data.features.find((item) => item.place_type[0] === "municipality");
                                let county = data.features.find((item) => item.place_type[0] === "county");
                                let state = data.features.find((item) => item.place_type[0] === "state");

                                that.city = {
                                    "city": place ? place.text : null,
                                    "province": state ? state.text : null,
                                    "powiat": county ? county.text : null,
                                    "gmina": municipality ? municipality.text : null,
                                    "latitude": position.coords.latitude,
                                    "longitude": position.coords.longitude
                                }

                            }, true);

                    }, (error) => {
                        // console.log(error);
                    });
                } catch (e) {
                    // console.log(e);
                }
            },
            toggleList: function () {
                if (this.showContentSearch) {
                    this.showJsCloseResults = false;
                    this.closeBox();
                } else {
                    this.openBox();
                }
            },
            remoteIsDisabled: function () {
                if (this.area === 'Centrala') {
                    this.remote = false;

                }
            },
            openBox: function () {
                this.showContentSearch = true;
                this.isOpen = true;
            },
            closeBox: function () {
                this.showContentSearch = false;
                this.isOpen = false;
            },
            showPreloader: function () {
                if (this.settings.loaderClassName && !window.dontLoadList && document.getElementsByClassName(this.settings.loaderClassName)[0]) {
                    document.getElementsByClassName(this.settings.loaderClassName)[0].classList.add(this.settings.loaderVisibleClassName);
                }
                this.showJsCloseResults = false;
            },
            hidePreloader: function () {
                if (this.settings.loaderClassName && !window.dontLoadList && document.getElementsByClassName(this.settings.loaderClassName)[0]) {
                    document.getElementsByClassName(this.settings.loaderClassName)[0].classList.remove(this.settings.loaderVisibleClassName);
                }
            },
            hideList: function () {
                this.showListWithResults = false;
                this.showListWithoutResults = false;
            },
            openDepartment: function (department) {
                this.department = department;
                this.getList();
            },
            openCity: function (city) {
                this.city = city;
                this.getList();
            },
            openCategory: function (category) {
                this.category = category;
                this.getList();
            },
            selected: function () {
                if (this.isOpen && !window.onlyForm && !window.liksPage && !window.withoutCv) {
                    this.getList();
                }
            },
            setMarker: function (lng, lat) {
                if (this.isOpen) {
                    if (lng && lat) {
                        this.list = this.listBackup.filter((item) => item.latitude === lat && item.longitude === lng);
                    } else {
                        this.list = this.listBackup;
                    }
                    this.setCurrentPage(1);
                }
            },
            filterByGeolocation: function (getMap) {
                if (this.city && this.distance) {
                    this.list = this.listBackup.filter((item) => {
                        if (isNaN(item.latitude) || isNaN(item.longitude)) { //remote, cała Polska
                            return true;
                        }
                        const dist = this.distanceCalc(item.latitude, item.longitude, this.city.latitude, this.city.longitude);
                        return dist <= this.distance.code;
                    });
                } else {
                    this.list = this.listBackup;
                }
                if (this.list.length > 0) {
                    this.showListWithResults = true;
                    this.showListWithoutResults = false;
                } else {
                    this.showListWithResults = false;
                    this.showListWithoutResults = true;
                }

                let that = this
                if (getMap) {
                    setTimeout(function () {
                        loadMap(false, that.list, that.city ? that.city.latitude : null, that.city ? that.city.longitude : null, true)
                    });
                }
                this.setCurrentPage(1);
                if (!document.body.classList.contains('page-lp')) {
                    document.querySelector('#map') ? window.runScroll('#map', 0) : false;
                }
            },
            searchOnChange: function () {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(this.getList, 300);
            },
            clearSearch: function () {
                this.search = '';
                this.selected();
            },
            search2OnChange: function () {
                this.searchOnChange()
            },
            clearSearch2: function () {
                this.search2 = '';
                this.selected();
            },
            toggleLike: function (id) {
                if (this.liked.indexOf(id) === -1) {
                    this.liked.push(id);
                } else {
                    this.liked.splice(this.liked.indexOf(id), 1);
                }
                if (this.type == 'it')
                    localStorage.setItem('likedit', JSON.stringify(this.liked));
                else
                    localStorage.setItem('liked', JSON.stringify(this.liked));
            },
            clearForm: function () {
                this.it_position = null;
                this.it_department = null;
                this.it_work_type = null;
                this.city = null;
                this.area = null;
                this.department = null;
                this.title = null;
                this.remote = false;
                this.distance = {label: '+ 15 km', code: 15};
            },
            clearFormIt: function () {
                this.clearForm();
                history.replaceState(null, '', location.href.split('?')[0]);
                // this.searchIt();
            },
            searchIt: function () {

                if (document.getElementById('app-erecruter').dataset.searchUrl) {
                    let url = '?';

                    if (this.it_position) {
                        url += 'position=' + this.it_position.map(item => item.label).join('||') + '&';
                    }

                    if (this.it_department) {
                        url += 'department=' + this.it_department.join('||') + '&';
                    }

                    if (this.it_work_type) {
                        url += 'work_type=' + this.it_work_type.join('||') + '&';
                    }

                    if (this.city) {
                        url += 'city=' + this.city.map(item => item.city).join('||') + '&';
                    }

                    window.location.href = document.getElementById('app-erecruter').dataset.searchUrl + url;

                    return;
                }

                let currentUrl = window.location.href;
                let baseUrl = currentUrl.split('?')[0];
                history.replaceState(null, '', baseUrl);

                this.getItList(true)
            },
            getItList: function (firstLoad = false) {
                this.list = this.it_raw_list;

                if (this.$el.dataset.liked == 'true') {
                    this.list = this.list.filter((item) => this.liked.indexOf(item.id) !== -1);
                }

                if (this.it_work_type && this.it_work_type.length > 0) {

                    this.list = this.it_raw_list.filter((item) => {
                        let result = false;

                        this.it_work_type.forEach((type) => {
                            if (item.mode.includes(type))
                                result = true;
                        });

                        return result;
                    });
                }

                if (this.city && this.city.length > 0) {

                    this.list = this.it_raw_list.filter((item) => {
                        let result = false;

                        this.city.forEach((city) => {
                            if (item.location.includes(city.city))
                                result = true;
                        });

                        return result;
                    });

                }

                if (this.it_department && this.it_department.length > 0) {

                    this.list = this.it_raw_list.filter((item) => {
                        let result = false;

                        this.it_department.forEach((department) => {
                            if (item.category.includes(department))
                                result = true;
                        });

                        return result;
                    });
                }

                if (this.it_position && this.it_position.length > 0) {

                    this.list = this.it_raw_list.filter((item) => {
                        let result = false;

                        this.it_position.forEach((position) => {
                            if (item.title.includes(position.label))
                                result = true;

                            if (item.required_technologies.includes(position.label))
                                result = true;

                            if (item.additional_technologies.includes(position.label))
                                result = true;

                        });

                        return result;
                    });
                }

                this.reloadPagination();
            },
            getList: function (firstLoad = false) {
                let that = this;
                setTimeout(function () {
                    let city = that.city === 'Cała Polska' ? null : that.city;
                    let area = that.area === 'Wszystkie' ? null : that.area;
                    let department = that.department === 'Wszystkie' ? null : that.department;
                    let distance = that.distance === 'Wszystkie' ? null : that.distance;
                    let remote = that.remote ? that.remote : false;
                    let search = that.search ? that.search : null;
                    let search2 = that.search2 ? that.search2 : null;
                    let url = '';

                    if (city || area || search || search2 || department || distance) {
                        url = '?';
                        if (city) {
                            url += 'city=' + JSON.stringify(city) + '&';
                        }
                        if (area) {
                            url += 'area=' + area + '&';
                        }
                        if (department && department.length) {
                            url += 'departments_arr=' + department.join('||') + '&';
                        }
                        if (distance) {
                            url += 'distance=' + distance.code + '&';
                        }
                        if (that.title) {
                            url += 'titles_arr=' + that.title + '&';
                        }
                        if (remote && that.type != 'it') {
                            url += 'remote=' + remote + '&';
                        }
                        if (search) {
                            url += 'search=' + search + '&';
                        }
                        if (search2) {
                            url += 'search2=' + search2 + '&';
                        }
                    }

                    //@todo: czemu tak? nie można dać type='+ that.type +'&'? sprawdzić
                    if (that.type == 'fachowcy') {
                        if (url.length > 0)
                            url += 'type=fachowcy&';
                        else
                            url = '?type=fachowcy&';
                    } else if (that.type == 'it') {
                        if (url.length > 0)
                            url += 'type=it&';
                        else
                            url = '?type=it&';
                    } else {
                        if (url.length > 0)
                            url += 'type=kariera&';
                        else
                            url = '?type=kariera&';
                    }

                    if (firstLoad !== true && (window.onlyForm || window.liksPage === true || window.withoutCv === true)) {
                        if (that.department && that.department.includes('IT')) {
                            window.location.href = window.location.href.split('/').slice(0, 3).join('/') + '/' + that.resultsItPage;
                        } else {
                            window.location.href = window.location.href.split('/').slice(0, 3).join('/') + '/' + that.resultsPage + url;
                        }
                    } else {
                        if (firstLoad !== true) {
                            try {
                                if (!offerFilter)
                                    history.replaceState('', null, window.location.href.split('/').slice(0, 3).join('/') + '/' + that.resultsPage + url);
                            } catch (e) {
                            }
                        }

                        if ((!department || !department.length) && area) {
                            url += 'departments_arr=' + that.departmentsGenerator().join('||') + '&';
                        }

                        that.request("/api/list/" + that.settings.key + url, function (data) {
                            that.setCurrentPage(1);
                            let downloadedData = [];
                            let itPositions = [];
                            let itCategories = [];

                            if (that.type == 'it' && !that.it_raw_list.length) {
                                //budowanie bazy dla it
                                data.forEach((item) => {

                                    item.required_technologies.forEach((technology) => {
                                        itPositions.push({
                                            label: technology
                                        });
                                    });

                                    item.additional_technologies.forEach((technology) => {
                                        itPositions.push({
                                            label: technology
                                        });
                                    });

                                    itPositions.push({
                                        label: item.title
                                    });

                                    itCategories.push(item.category);
                                });


                                that.it_raw_positions = itPositions.filter((v, i, a) => a.findIndex(t => (t.label === v.label)) === i);
                                that.it_raw_positions = that.it_raw_positions.sort((a, b) => a.label.localeCompare(b.label));

                                // that.it_departments = itCategories.filter((v, i, a) => a.findIndex(t => (t === v)) === i);
                                that.it_departments = IT_CATEGORIES.sort((a, b) => a.localeCompare(b));
                                console.log(IT_CATEGORIES)

                                that.it_raw_list = data;

                                setTimeout(() => that.getItList(true));
                                setTimeout(() => that.updateLikedOffers());
                            }

                            if (offerFilter) {
                                for (const key in offerFilter) {

                                    switch (key) {
                                        case 'area':
                                            const categories = that.categoriesGenerator().find((item) => item.name === offerFilter[key]);
                                            if (!categories)
                                                continue;

                                            data = data.filter((item) => {

                                                return categories.list.map(x => x.toUpperCase()).includes(item['category'])
                                            });
                                            break;
                                        case 'location':
                                            that.city = offerFilter[key];
                                            break;
                                        case 'department':
                                        case 'distance':
                                            that[key] = offerFilter[key];
                                            break;
                                        default:
                                            data = data.filter((item) => {

                                                if (Array.isArray(offerFilter[key])) {
                                                    return offerFilter[key].indexOf(item[key]) !== -1;
                                                }

                                                return item[key] === offerFilter[key]
                                            });

                                    }

                                    that.filterByGeolocation(false)
                                }
                            }

                            if (window.liksPage) {
                                downloadedData = data.filter((item) => that.liked.indexOf(item.id) !== -1);
                                if (that.liked.length !== downloadedData.length) {
                                    that.liked = that.liked.filter((item) => downloadedData.find((el) => el.id === item) !== undefined)
                                    if (that.type == "it")
                                        localStorage.setItem('likedit', JSON.stringify(that.liked));
                                    else
                                        localStorage.setItem('liked', JSON.stringify(that.liked));
                                }
                            } else {
                                downloadedData = data;
                            }

                            //empty city to the end
                            that.list = [...downloadedData.filter((item) => item.city !== ''), ...downloadedData.filter((item) => item.city == '')];

                            if(ids.length > 0){
                                that.list = that.list.filter((item) => ids.includes(item.id));
                            }

                            if (that.area === 'Centrala' ) {

                                const departmentsToCheck = [
                                    { department: 'Przedstawiciel handlowy', title: 'Przedstawiciel / Przedstawicielka handlowa' },
                                    { department: 'Zasoby ludzkie', title: 'HR Biznes Partner / Partnerka' },
                                    { department: 'Finanse', title: 'Lider / Liderka Efektywności Operacyjnej' }
                                ];

                                const promises = departmentsToCheck.map(dept => {
                                    if (that.department && that.department.indexOf(dept.department) !== -1) {
                                        return that.findAdvertWithTitle(dept.title);
                                    }
                                    return Promise.resolve([]);
                                });

                                Promise.all(promises).then(results => {
                                    results.forEach(advs => {
                                        that.list = [...that.list, ...advs];
                                    });
                                    that.makeListingEndProcess(that.list, that.city, offerFilter);
                                });

                            }
                            // else if (that.area === 'Sklep' && that.department && that.department.indexOf('Biuro') !== -1) {
                            //
                            //     that.findAdvertWithTitle(['Przedstawiciel / Przedstawicielka handlowa','Lider / Liderka Efektywności Operacyjnej','HR Biznes Partner / Partnerka']).then((advs) => {
                            //         that.list = [...that.list, ...advs];
                            //         that.makeListingEndProcess(that.list, that.city, offerFilter);
                            //     });
                            // }
                            else {
                                that.makeListingEndProcess(that.list, that.city, offerFilter);
                            }

                        });
                        that.openBox();
                        if (!offerFilter && !document.body.classList.contains('page-lp'))
                            document.querySelector('#map') ? window.runScroll('#map', 0) : false;
                    }
                })
            },
            makeListingEndProcess: function (list, city, offerFilter) {
                this.listBackup = list;
                if (list.length > 0) {
                    this.showListWithResults = true;
                    this.showListWithoutResults = false;
                    this.showJsCloseResults = true;
                } else {
                    this.showListWithResults = false;
                    this.showListWithoutResults = true;
                }
                this.reloadPagination();

                if (city) {
                    this.filterByGeolocation(false);
                    setTimeout( () => {
                        loadMap(false, this.list, city.latitude, city.longitude, true)
                    });
                } else {
                    let lat, lng;

                    if (offerFilter && list.length > 0) {
                        lat = list.find((item) => item.latitude !== null).latitude;
                        lng = list.find((item) => item.longitude !== null).longitude;
                    }

                    setTimeout( () => {
                        loadMap(false, list, lat, lng, true)
                    });
                }
            },
            findAdvertWithTitle: function (title) {
                const that = this;
                return new Promise((resolve, reject) => {
                    const advs = [];
                    that.request("/api/list/" + that.settings.key, function (data) {

                        if (!data.length) {
                            resolve(advs);
                            return;
                        }
                        data.forEach((advItem) => {
                            if (typeof title === 'string' && advItem.title.toLowerCase() === title.toLowerCase()) {
                                advs.push(advItem);
                            } else if( Array.isArray(title) && title.includes(advItem.title)){
                                advs.push(advItem);
                            }
                        });

                        // console.log("ogloszenia", advs);
                        resolve(advs);
                    });
                });
            },
            reloadPagination: function () {
                this.totalPages = Math.ceil(this.list.length / this.perPage);
                this.indexOfLastOffer = this.currentPage * this.perPage;
                this.indexOfFirstOffer = this.indexOfLastOffer - this.perPage;
                this.currentOffers = this.list.slice(this.indexOfFirstOffer, this.indexOfLastOffer);

                if (this.it_department_page) {
                    const offers = this.list.filter((item) => item.category.toLowerCase().includes(this.it_department_page.toLowerCase()));
                    this.currentOffers = offers.slice(this.indexOfFirstOffer, this.indexOfLastOffer);
                } else {
                    this.currentOffers = this.list.slice(this.indexOfFirstOffer, this.indexOfLastOffer);
                }

                if (this.totalPages < 4) {
                    this.showPages = this.range(1, this.totalPages);
                } else if (this.currentPage - 1 > 0 && this.currentPage + 1 < this.totalPages) {
                    this.showPages = this.range(this.currentPage - 1, this.currentPage + 1);
                } else if (this.currentPage - 1 < 1) {
                    this.showPages = this.range(1, 3);
                } else {
                    this.showPages = this.range(this.totalPages - 2, this.totalPages);
                }

                this.showPagination = this.list.length > this.perPage;
            },
            range: function (from, to) {
                let arr = [];
                for (let i = from; i <= to; ++i) {
                    arr.push(i);
                }
                return arr;
            },
            setCurrentPage: function (page) {
                this.currentPage = page;
                this.reloadPagination();
            },
            nextPage: function () {
                if (this.currentPage < this.totalPages) {
                    this.setCurrentPage(this.currentPage + 1);
                }
            },
            prevPage: function () {
                if (this.currentPage > 1) {
                    this.setCurrentPage(this.currentPage - 1);
                }
            },
            request: function (url, callback, externalUrl = false, onlyRequest = false) {
                var request = new XMLHttpRequest();
                var that = this;

                if (!onlyRequest) {
                    that.showPreloader();
                    that.hideList();
                    request.onreadystatechange = function () {
                        if (request.readyState === 4 && request.status === 200) {
                            that.hidePreloader();
                        } else {
                            that.showPreloader();
                        }
                    }
                }

                request.open('GET', (externalUrl ? '' : this.settings.url) + url, true);

                request.onload = function () {
                    if (this.status >= 200 && this.status < 400) {
                        callback(JSON.parse(this.response))
                    }
                };
                request.send();
            },
            distanceCalc: function (lat1, lon1, lat2, lon2) {
                let radlat1 = Math.PI * lat1 / 180
                let radlat2 = Math.PI * lat2 / 180
                let theta = lon1 - lon2
                let radtheta = Math.PI * theta / 180
                let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
                dist = Math.acos(dist)
                dist = dist * 180 / Math.PI
                dist = dist * 60 * 1.1515
                return dist * 1.609344
            },

            loadPromotedOffers: function () {
                let that = this;
                this.request("/api/list/" + this.settings.key + "?promoted=1&type=" + this.type, function (data) {
                    that.promotedOffers = data;

                    setTimeout(() => itFeaturedoffereCarousel());
                });
            },

            updateLikedOffers: function () {

                if (this.liked.length == 0) {
                    return;
                }

                //check if liked offers are still in the list this.list
                let newLiked = this.list.filter((item) => this.liked.indexOf(item.id) !== -1);
                if (newLiked.length !== this.liked.length) {
                    this.liked = newLiked.map((item) => item.id);
                    if (this.type == "it")
                        localStorage.setItem('likedit', JSON.stringify(this.liked));
                    else
                        localStorage.setItem('liked', JSON.stringify(this.liked));
                }

            },

            setItCategory: function (category) {
                this.it_department_page = category;
                this.reloadPagination();
            },

            openCustomTargets(targets) {
              const foundAdverts = [];
              this.request("/api/list/" + this.settings.key+'?type=kariera' ,  data => {

                targets.forEach((target) => {
                  const [lat,long] = target.split(':');
                  data.forEach((item) => {
                    if(item.latitude == lat && item.longitude == long){
                        foundAdverts.push(item);
                    }
                  });

                });

                // console.log(foundAdverts);
                this.list = foundAdverts;
                this.listBackup = this.list;
                if (this.list.length > 0) {
                  this.showListWithResults = true;
                  this.showListWithoutResults = false;
                  this.showJsCloseResults = true;
                } else {
                  this.showListWithResults = false;
                  this.showListWithoutResults = true;
                }
                this.reloadPagination();
                this.openBox();


                  setTimeout( ()=> {
                      targets.forEach((target) => {
                            const [lat,long] = target.split(':');
                            loadMap(false, this.list, lat, long, true)
                        });
                  });
              });


            }
        }
    })
}

